<template>
	<div class="register-page">
		<div style="padding:30px 0 20px;margin-left: 20px;">
			<img src="/images/register.png" style="width: 100px;">
		</div>
		
		<div class="posistiontop">
			<van-form @submit="onSubmit" :show-error="false" :validate-first="true">
				
				<div class="form-label">姓名<span>*</span></div>
				<van-field v-model="formData.realName" name="realName" label="" class="no-bg" placeholder="请输入姓名" :rules="rules.realName" required />
				
				<div class="form-label" style="margin-bottom: 10px;">性别<span>*</span></div>
				<van-field name="sex" label="" class="no-bg" required>
					<template #input>
						<van-radio-group v-model="formData.sex" direction="horizontal">
							<van-radio name="1" >男</van-radio>
							<van-radio name="0" style="margin-left:30px;">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				
				<div class="form-label">年龄<span>*</span></div>
				<van-field v-model="formData.age" type="digit" name="age" class="no-bg" label="" placeholder="请输入年龄" required :rules="rules.age" />
				
				<div class="form-label">身份证号</div>
				<van-field v-model="formData.cardNo" type="text" name="cardNo" label="" class="no-bg" placeholder="请输入身份证号" :rules="rules.cardNo" />
				
				<div class="form-label">身高</div>
				<van-field v-model="formData.height" type="digit" name="height" label="" class="no-bg" placeholder="请输入身高" :rules="rules.height">
					<template #extra> cm </template>
				</van-field>
				
				<div class="form-label">体重</div>
				<van-field v-model="formData.weight" type="digit" name="weight" label="" class="no-bg" placeholder="请输入体重" :rules="rules.weight">
					<template #extra> kg </template>
				</van-field>
				
				<div style="position: fixed;left:20px;right:20px;bottom: 20px;">
					<van-button block type="primary"  native-type="submit" color="#2D6FDF" round>注册</van-button>
					<div style="margin-top: 10px;text-align: center;font-size: 12px;color: #2a2a2a;">
						注册即表示已阅读并同意 <a @click="$router.push({name:'agreement'})" style="color: #2D6FDF;">《用户服务协议》</a>
					</div>
				</div>
			</van-form>
		</div>
		
	
	</div>
</template>
<script>
	import {Form , Field , Button , NavBar , RadioGroup , Radio , CountDown , Cell , Image as VanImage , Uploader , Checkbox , } from "vant";
	import { register, getToken } from "@api/system";
	import { regSend } from "@api/verification";
	import { getCookie , setCookie } from "@/util/storage.js";

	export default {
		name: "register",
		data() {
			let _num = (value, rule)=>{
				if(value){
					let exp = /^[1-9]\d*$/;
					if(exp.test(value)){
						return true
					}else{
						return false
					}
				}else{
					return true
				}
			};
			let _cardNo  = (value, rule)=>{
				if(value){
					let exp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
					if(exp.test(value)){
						return true
					}else{
						return false
					}
				}else{
					return true
				}
			}
			return {
				formData:{	
					id:"",
					phone: "",
					cardNo: "",
					code: "",
					wxCode:"",
					age: "",
					realName: "",
					sex: "1",
					height: '',
					weight: '',
				},
				checked: false,
				rules:{
					realName:[{ required: true,message:'请输入姓名' }],
					age:[{ required: true,pattern:/^([0-9]|[1-9][0-9]|(1[01][0-9]|120))$/, message: '请填写正确的年龄' }],
					cardNo:[{  required: false,  validator:_cardNo, message: '请填写正确的身份证号' }],
					height:[{ required: false, validator:_num, message: '请填写正确的身份证号' }],
					weight:[{ required: false,  validator:_num, message: '请填写正确的体重' }],
				}
			};
		},
		components: {
			[Form.name]: Form,
			[Field.name]: Field,
			[Button.name]: Button,
			[NavBar.name]: NavBar,
			[Radio.name]: Radio,
			[RadioGroup.name]: RadioGroup,
			[CountDown.name]: CountDown,
			[Cell.name]: Cell,
			[VanImage.name]: VanImage,
			[Uploader.name]: Uploader,
			[Checkbox.name]: Checkbox,
		},
		computed: {},
		created() {
			console.log(this.$route.query);			
			// let wxCode = getCookie("wxCode");
			
			let { phone , id , wxCode } = this.$route.query;
			if(phone){
				this.formData.phone = phone
			}
			if(id){
				this.formData.id = id;
			}
			if(wxCode){
				this.formData.wxCode = wxCode;
			}
		},
		methods: {
			calAge(event) {
				this.getAge();
			},
			getAge() {
				let birthday = "";
				let length = this.cardNo.length;
				if (this.cardNo.length == 15 || this.cardNo.length == 18) {
					if (length === 15) {
						birthday = "19" + this.cardNo.substr(6, 6);
					} else if (length === 18) {
						birthday = this.cardNo.substr(6, 8);
					}
					let year = birthday.substr(0, 4);
					let mounth = birthday.substr(4, 2);
					let day = birthday.substr(6, 2);
					let d = new Date();
					let age =
						d.getFullYear() -
						year -
						(d.getMonth() < mounth ||
							(d.getMonth() == mounth && d.getDate() < day) ?
							1 :
							0);
					this.age = age;
				} else {
					this.age = "";
				}
			},
			onSubmit(values) {
				// console.log("submit", values);				
				const params = { 
					...this.formData,
					sex:Number(this.formData.sex)
				};
				console.log(params)
				register(params).then((res) => {
					if (res.respCode == 200) {						
						this.$toast("注册成功");		
						
						if(res.result.token){
							window.localStorage.setItem("token", res.result.token);
						};
						if(res.result.user){
							setCookie('userinfo',res.result.user);
						};			
						
						let redirect = getCookie('redirect')
						if(redirect){
							this.$router.replace(redirect)
						}else{
							this.$router.replace('/personal')
						}
						
					} else {
						this.$toast(res.respMsg);
					}
				});
			}
		},
		mounted() {
			
		},
	};
</script>
<style lang="less" scope>
	
	.register-page{
		// background: url(/images/img_bg.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	
	
	.form-label{
		margin: 10px 15px 0;font-size:16px;font-weight:bold;line-height: 20px;overflow: hidden;
		span{color: #f00;margin-left: 3px;}
	}
	
	.cutdown {
		font-size: 12px; color: #999;
	}
	
	.van-count-down {
		display: inline;
	}
	
	
	.posistiontop {
		padding:0 5px;		
	}
	
	.no-bg{padding-top:4px;background: transparent;}
	.no-bg::before{display: none;}
	
</style>
